<template>
    <div class="leads-table-area campaigns-list-area addcustomer-table">
        <router-link :to="{name:'addNewEmailCampaigns'}" class="rem-btn add-campaign-list-btn">Add New Campaign  <fa icon="plus" class="ml-1"/></router-link>
        <table id="campaigns_table" class="display">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Users Category</th>
                    <th>Status</th>
                    <th>Created</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody></tbody>
        </table>

        <!-- Campaign Detail Modal -->
        <div class="modal fade" id="campaignDetailModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="previousLeadsLabel">Campaign</h5>
                        <a class="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><img class="img-fluid" src="@/assets/images/icons/Closex.svg" alt="" /></span>
                        </a>
                    </div>
                    <div class="modal-body">

                        <div class="row">
                            <div class="col-md-6">
                                <div class="details-modal">
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td class="title">Title:</td>
                                                <td>{{campaign_detail.name}}</td>
                                            </tr>
                                            <tr>
                                                <td class="title">From Name:</td>
                                                <td>{{campaign_detail.from_name}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="details-modal">
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td class="title">Subject:</td>
                                                <td>{{campaign_detail.subject}}</td>
                                            </tr>
                                            <tr>
                                                <td class="title">From Email:</td>
                                                <td>{{campaign_detail.from_email}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="notes-con" v-if="campaign_detail.template">
                                    <h3>Template</h3>
                                    <iframe id="myframe" width="100%" height="400px" :srcdoc="campaign_detail.template"></iframe>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import $ from 'jquery'
import { useToast } from "vue-toastification";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";

export default {
    data(){
		return{
            campaign_detail:{}
        }
    },
    setup () {
        const toast = useToast();
        return {toast }
    },
	methods:{
        changeCampaignStatus(id,status){
            let thiss = this
            let msg = '';
            if (status == 0) {
                msg ='Active'
            }else if(status == 1){
                msg = "InActive"
            }
            this.$swal({
                title: 'Are you sure?',
                text: "You want to change campaign status to "+msg,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: false,
                customClass: {
                    confirmButton: 'rem-btn mr-3',
                    cancelButton: 'add-btn'
                },
                buttonsStyling: false
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('change_campaign_status',{id:id,status:status}).then(function (response) {
                        if (response.data.status == 'success') {
                            $("#campaigns_table").dataTable().fnDraw();
                            thiss.toast.success(response.data.message);
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status == 422) {
                                thiss.toast.error(error.response.data.message);
                            }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                                thiss.$storage.removeStorageSync("user")
                                thiss.$storage.removeStorageSync("token")
                                thiss.$storage.removeStorageSync("business")
                                thiss.$router.push({ name: 'Home'})
                            }else if (error.response.status == 401) {
                                thiss.toast.error(error.response.data.message,{position: "top-right"});
                            }
                        }
                    });
                }
            })
        },
        viewCampaign(id){
            let thiss = this
            $("#campaignDetailModal").modal('show')
            axios.get('campaign_detail/'+id).then(function (response) {
                if (response.data.status == 'success') {
                    thiss.campaign_detail = response.data.data
                }else{
                    thiss.campaign_detail = {}
                }
            })
			.catch(error => {
                if (error.response) {
                    if (error.response.status == 422) {
                        thiss.toast.error(error.response.data.message);
                    }
                }
            });
        }
    },
    mounted(){
        let thiss = this
        $('#campaigns_table').addClass('nowrap').dataTable({
            "responsive": true,
            "fixedHeader": true,
            "processing": true,
            "serverSide": true,
            ajax:{ 
                url:axios.defaults.baseURL+'dt_campaigns',
                type: 'POST',
                headers: {
                    Authorization: axios.defaults.headers.common['Authorization'],
                },
                data: function (d) {
                    d.business_id = thiss.$storage.getStorageSync('business').active_business.id;
                },
            }
        });
        let vm = this;
        $('tbody').on( 'click', '.clStatus', function(e){
            e.preventDefault();
            let id = $(this).data("id")
            let status = $(this).data("status")
            vm.changeCampaignStatus(id,status)
        });
        $('tbody').on( 'click', '.clView', function(){
            let id = $(this).data("id")
            vm.viewCampaign(id)
        });
    }
}
</script>
<style scoped>
@import "../../../assets/css/dashboard/dataTables.css";
</style>
<style scoped>
    .add-campaign-list-btn{
        position:absolute;
        right: 12px;
        top: 13px;
        z-index: 1;
    }
    .campaigns-list-area{
        position: relative;
    }
    .details-modal .table td{
        font-size: 15px;
    }
    .details-modal .table td.title{
        font-weight: 600;
    }
</style>
<style>
    .campaigns-list-area .dataTables_filter {
        margin-right: 222px;
    }
</style>